import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { CheckCircleOutlineOutlined } from "@material-ui/icons";
import queryString from "query-string";
import api from "../../api";
import logo from "../../assets/images/100ms-logo.png";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { nearestRegion } from "../../helpers/region_helper";
import { createGetURL, getRolesFromPublicTemplate } from "../../helpers/utils";
import { currentUser } from "../../utils";
import VercelLogin from "../Authentication/VercelLogin/index";

class VercelIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Adding core dependencies...",
      percentComplete: 0,
      title: "Linking your 100ms project to vercel",
      error: false,
      errorType: "",
      onBoarding: document.location.hash ? false : true,
      signedIn: false,
      loading: true,
      templateId: "",
      starterKitId: "",
      roles: [],
      event_type: "",
    };
  }

  returnToVercel() {
    window.location.href = this.state.vercel_redirect;
  }

  async postVerification() {
    const clientId = process.env.REACT_APP_DATOCMS_CLIENT_ID;
    const redirectURI = `${window.location.origin}/vercel-template-integration/cms-datocms`;
    const { search, hash } = document.location;
    const { configurationId } = queryString.parse(search);

    if (hash && search) {
      this.setState({
        ...this.state,
        percentComplete: 30,
        title:
          "Linking your 100ms and datocms account, integrating them to your vercel project",
        onBoarding: false,
      });
      const { vercel_code, vercel_redirect } = queryString.parse(
        window.location.search
      );
      const { access_token } = queryString.parse(window.location.hash);
      const region = (await nearestRegion()).toLowerCase();
      const body = {
        datocms_access_token: access_token,
        configuration_id: configurationId,
        vercel_code,
        region,
        roles: this.state.roles,
        event_type: this.state.event_type,
      };
      if (this.state.starterKitId && this.state.templateId) {
        body["starter_kit_id"] = this.state.starterKitId;
        body["ts_template_id"] = this.state.templateId;
      }
      let timeout1, timeout2;
      timeout1 = setTimeout(() => {
        this.setState({
          ...this.state,
          percentComplete: 60,
        });
        timeout2 = setTimeout(() => {
          if (this.state.percentComplete !== 100 && !this.state.error) {
            this.setState({
              ...this.state,
              percentComplete: 93,
            });
          }
        }, 4000);
      }, 4000);
      api
        .service("dashboard")
        .post("integrations/vercel-datocms-integration", body)
        .then(() => {
          this.setState({
            ...this.state,
            percentComplete: 100,
            status: `Result is success`,
            vercel_redirect,
          });
        })
        .catch(error => {
          const error_message = error?.response?.data?.msg;
          if (timeout1) {
            clearTimeout(timeout1);
          }
          if (timeout2) {
            clearTimeout(timeout2);
          }
          this.setState({
            ...this.state,
            percentComplete: 1,
            error: true,
            status: error_message,
            errorType: error?.response?.data.error_type,
          });
        });
    } else {
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            percentComplete: 10,
            title: "Linking vercel project with DatoCMS",
          }),
        2000
      );
      const searchParams = queryString.parse(window.location.search);
      const { code, next, configurationId } = searchParams;
      const externalId =
        searchParams["external-id"] ||
        process.env.VERCEL_DATOCMS_CMS_STARTER_KIT_ID ||
        3;
      const redirect_url = createGetURL(
        "https://oauth.datocms.com/oauth/authorize?",
        {
          client_id: clientId,
          response_type: "token",
          redirect_uri: decodeURIComponent(
            createGetURL(redirectURI, {
              vercel_code: code,
              vercel_redirect: next,
              configurationId,
              "external-id": externalId,
            })
          ),
        }
      );
      setTimeout(() => (window.location.href = redirect_url), 1000);
    }
  }

  async fetchStarterKitFromTemplate(starterKitId) {
    await api
      .service("cms")
      .get(
        `api/starter-kits/${starterKitId}`,
        {
          populate:
            "template.roles.subscribeTo,template.roles.videoPermission,template.roles.screensharePermission",
        },
        {
          headers: null,
        }
      )
      .then(response => {
        const templateId = response?.data?.data?.attributes?.template?.data?.id;
        const templateData = response?.data?.data?.attributes?.template?.data;
        const roles = getRolesFromPublicTemplate(templateData);
        const event_type = templateData
          ? templateData.attributes.type
          : "CREATEOWN";
        this.setState({
          ...this.state,
          roles,
          templateId,
          starterKitId,
          event_type,
        });
      })
      .catch(error => {
        console.error(error);
        AppAnalytics.track("app.deploy.failure", {
          page: "/vercel-datocms-integration",
          data: {
            "starter-kit-id": starterKitId,
            message:
              "vercel datocms integration failed during fetching the template",
          },
        });
      });
  }

  async componentDidMount() {
    if (currentUser()) {
      this.setState({
        ...this.state,
        signedIn: true,
        loading: false,
      });
    } else {
      this.setState({
        ...this.state,
        signedIn: false,
        loading: false,
      });
    }
    const searchParams = queryString.parse(window.location.search);
    const externalId =
      searchParams["external-id"] ||
      process.env.VERCEL_DATOCMS_CMS_STARTER_KIT_ID ||
      3;
    if (externalId) {
      await this.fetchStarterKitFromTemplate(externalId);
    }
    if (!this.state.onBoarding) {
      this.postVerification();
    } else if (!this.state.signedIn && searchParams.stepsShown) {
      this.setState({
        ...this.state,
        onBoarding: false,
      });
    }
  }
  // eslint-disable-next-line complexity
  render() {
    let screen;
    let onBoarding;
    const nextButton = message => (
      <button
        className="btn btn-primary btn-block waves-effect waves-light w-44 mt-4"
        onClick={() => {
          if (this.state.signedIn) {
            this.setState({
              ...this.state,
              percentComplete: 5,
              title: "Please wait for a moment...",
              onBoarding: false,
            });
            this.postVerification();
          } else {
            this.setState({
              ...this.state,
              percentComplete: 0,
              onBoarding: false,
            });
          }
        }}
      >
        {message}
      </button>
    );
    if (this.state.onBoarding) {
      if (this.state.signedIn) {
        onBoarding = (
          <div className="font-extralight text-center font-inter text-[#999A99] text-xl mb-10">
            <p>
              Successfully linked your 100ms account to vercel.
              <br />
              Redirecting you to link your datocms account
            </p>
            {nextButton("Next")}
          </div>
        );
      } else {
        onBoarding = (
          <div className="flex justify-center text-center">
            <div className="font-extralight font-inter text-[#999A99] text-xl mb-10">
              <p>
                Add live audio-video to your vercel
                <br />
                project in 5 minutes
              </p>

              <div className="border border-current rounded mt-4 p-4">
                <p className="my-2 text-left text-sm font-medium">
                  This integration will enable you to integrate both 100ms and{" "}
                  <br />
                  datocms to your vercel project
                </p>
                <div className="separator"></div>
                <div className="text-left my-2 text-sm font-medium">
                  <p className="my-2"> During this setup, we will help you:</p>
                  <div className="font-extralight">
                    {this.state.signedIn ? null : (
                      <p className="mt-2">
                        <CheckCircleOutlineOutlined className=" text-blue-800 mr-2" />
                        Create an account or login to 100ms
                      </p>
                    )}
                    <p className="mt-2">
                      <CheckCircleOutlineOutlined className=" text-blue-800 mr-2" />
                      Create an account or login to datocms
                    </p>
                    <p className="mt-2">
                      <CheckCircleOutlineOutlined className=" text-blue-800 mr-2" />
                      Link your 100ms and datocms account
                    </p>
                  </div>
                </div>
              </div>
              {nextButton("Get Started")}
            </div>
          </div>
        );
      }
    }
    if (this.state.percentComplete === 0) {
      screen = (
        <div className="flex justify-center">
          <VercelLogin />
        </div>
      );
    } else if (this.state.percentComplete !== 100) {
      screen = (
        <div>
          <div className="flex justify-center">
            <p
              className="text-2xl mb-10 text-center"
              style={{ maxWidth: "32rem" }}
            >
              {this.state.title}
            </p>
          </div>
          <div className="flex justify-center mb-0">
            <div className="w-3/5 bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${this.state.percentComplete}%` }}
              ></div>
            </div>
          </div>
          <div className="flex justify-center font-extralight text-[#999A99] text-xl">
            {this.state.error ? (
              <div className="text-red-500 h1">&#9888; </div>
            ) : (
              <>
                <p>{this.state.status}</p>
                <span className="pl-44">
                  {this.state.percentComplete}% done
                </span>
              </>
            )}
          </div>
        </div>
      );
    } else {
      this.returnToVercel();
    }
    let error;
    if (this.state.error) {
      error = (
        <>
          <div className="text-center m-auto text-2xl mt-0 max-w-lg pb-4">
            {this.state.status}
          </div>
          {this.state.errorType === "MAX_DATOCMS_PROJECT_LIMIT" ? (
            <div className="text-center">
              <button
                className="btn btn-primary btn-block waves-effect waves-light w-25"
                onClick={() =>
                  window.open("https://dashboard.datocms.com/projects")
                }
              >
                Open DatoCMS Projects
              </button>
            </div>
          ) : (
            window.opener && (
              <div className="flex justify-center">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light w-25"
                  onClick={() => {
                    window.close();
                  }}
                >
                  Return to Vercel
                </button>
              </div>
            )
          )}
        </>
      );
    }
    if (this.state.loading) {
      return (
        <div className="flex justify-center mt-32">
          <Spinner color="primary" />
        </div>
      );
    }
    return (
      <div>
        <div className="flex justify-center items-center mt-12 mb-10">
          <img className="" src={logo} alt="100ms logo" width={200} />
        </div>
        {this.state.onBoarding ? onBoarding : screen}
        {error}
      </div>
    );
  }
}

export default VercelIntegration;
